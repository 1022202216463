.Footer {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Footer .icon {
  font-size: 1.5rem;
  margin: 0.75rem;
}

.Footer a {
  color: gray;
}

.Footer a:hover {
  color: #d4af37;
}
