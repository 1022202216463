@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap);
.Header {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
}

.Header .logo {
  width: 2rem;
  height: 2rem;
}

.Header .logo:hover {
  /* change color of PNG image via CSS */
  -webkit-filter: invert(89%) sepia(78%) saturate(5061%) hue-rotate(325deg)
    brightness(87%) contrast(89%);
          filter: invert(89%) sepia(78%) saturate(5061%) hue-rotate(325deg)
    brightness(87%) contrast(89%);
}

.Header a {
  margin-left: 1rem;
  margin-right: 1rem;
  color: gray;
  text-decoration: none;
}

.Header a:hover {
  color: #d4af37;
  text-decoration: none;
}

.Footer {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Footer .icon {
  font-size: 1.5rem;
  margin: 0.75rem;
}

.Footer a {
  color: gray;
}

.Footer a:hover {
  color: #d4af37;
}

.Home .profile-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.Home .pfp-container {
  float: left;
  padding: 1rem;
  max-width: 30rem;
}

.Home .pfp-container img {
  width: 100%;
}

.Home .bio {
  max-width: 38rem;
  padding: 1rem;
}

.Home .greeting {
  font-size: 2.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.Projects {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Projects a,
.Projects a:hover {
  text-decoration: none;
}

.Projects .card {
  color: black;
  margin-bottom: 1rem;
  border-radius: 1rem;
}

.Projects .card:hover {
  opacity: 0.7;
}

.Projects .card-title {
  text-align: center;
  font-weight: bold;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}

.Projects .card-img-top {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.Projects .card-full-width {
  max-width: 54rem;
}

.Projects .card-half-width {
  max-width: 27rem;
}

.CV {
  max-width: 36rem !important;
}

h1 {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

