.Header {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
}

.Header .logo {
  width: 2rem;
  height: 2rem;
}

.Header .logo:hover {
  /* change color of PNG image via CSS */
  filter: invert(89%) sepia(78%) saturate(5061%) hue-rotate(325deg)
    brightness(87%) contrast(89%);
}

.Header a {
  margin-left: 1rem;
  margin-right: 1rem;
  color: gray;
  text-decoration: none;
}

.Header a:hover {
  color: #d4af37;
  text-decoration: none;
}
